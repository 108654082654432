import adsNexusInit from '../ads_nexus.js';
import multiSlidersInit from '../multi_sliders.js';
import earlybirdsInit from '../earlybirds.js';
import { splideInit } from '../splide.js';
import homePageAdsInit from '../billboard.js';
import kaminoTrackingInit from '../billboard_tracking_video.js';

(() => {
    adsNexusInit();
    earlybirdsInit();
    splideInit();
    homePageAdsInit();
    kaminoTrackingInit();
    window.addEventListener('load', () => {
        multiSlidersInit();
    });
})();
