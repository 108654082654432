const VISIBILITY_THRESHOLD = 0.5;

// Fonction pour récupérer les paramètres spécifiques pour view de data-view-beacon
function getViewBeaconParams(beaconData, visibleTime) {
    if (!beaconData) {
        console.error('Missing beacon data');
        return null;
    }

    const params = new URLSearchParams(beaconData);
    const viewParams = new URLSearchParams();

    const tt = params.get('tt');
    const pid = params.get('pid');
    const pt = params.get('pt');
    if (!tt || !pid || !pt) {
        console.error('Missing required parameters in data-view-beacon:', { tt, pid, pt });
        return null;
    }

    viewParams.set('tt', tt);
    viewParams.set('pid', pid);
    viewParams.set('pt', pt);

    if (visibleTime !== null && visibleTime !== undefined) {
        viewParams.set('st', visibleTime);
    }

    return viewParams;
}

const sendBeaconTracking = (data) => {
    const trackingUrl = '/nav/extra/ajax/advertisement/tracking';
    const trackingData = new URLSearchParams({ params: data.toString() });
    const blob = new Blob([trackingData], { type: 'application/x-www-form-urlencoded' });
    navigator.sendBeacon(trackingUrl, blob);
};

// Fonction pour envoyer les paramètres avec sendBeaconTracking
function sendTracking(params, visibleTime) {
    if (!params) return;

    // Ajoutez visibleTime aux paramètres s'il est défini
    if (visibleTime !== undefined && visibleTime !== null) {
        params.set('st', visibleTime);
    }

    const url = new URLSearchParams(params).toString();
    sendBeaconTracking(url);
}

// Fonction pour vider le localStorage des clés spécifiques
function clearTrackingData() {
    Object.keys(localStorage).forEach((key) => {
        if (key.endsWith('_visibleTime') || key.endsWith('_hasTracked')) {
            localStorage.removeItem(key);
        }
    });
}

// Fonction pour suivre la visibilité du conteneur de la vidéo pendant 0, 1, et 2 secondes
const trackContainerVisibility = (videoContainer, viewBeacon, videoId) => {
    let visibleTime = parseInt(localStorage.getItem(`${videoId}_visibleTime`)) || 0;
    let hasTracked = localStorage.getItem(`${videoId}_hasTracked`) === 'true';
    let visibilityInterval;

    const startVisibilityInterval = () => {
        visibilityInterval = setInterval(() => {
            if (visibleTime <= 2) {
                const params = getViewBeaconParams(viewBeacon, visibleTime);
                if (params) {
                    sendTracking(params, visibleTime);
                }
                visibleTime++;
                localStorage.setItem(`${videoId}_visibleTime`, visibleTime);
            }
            if (visibleTime > 2) {
                clearInterval(visibilityInterval);
                hasTracked = true;
                localStorage.setItem(`${videoId}_hasTracked`, 'true');
            }
        }, 1000);
    };

    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && entry.intersectionRatio >= VISIBILITY_THRESHOLD) {
                    if (!hasTracked) {
                        startVisibilityInterval();
                    }
                } else {
                    clearInterval(visibilityInterval);
                }
            });
        },
        { threshold: VISIBILITY_THRESHOLD }
    );

    observer.observe(videoContainer);

    return () => {
        observer.disconnect();
        clearInterval(visibilityInterval);
    };
};

// Fonction d'initialisation du tracking pour chaque vidéo
const initVideoTracking = (video) => {
    const videoParent = video.closest('.video-area');
    const videoUrl = videoParent?.getAttribute('data-video-url');
    const videoId = video.getAttribute('id');
    if (!videoUrl) {
        console.error('Missing video URL');
        return;
    }

    const clickBeacon = videoParent.getAttribute('data-click-beacon');
    const videoBeacon = videoParent.getAttribute('data-video-beacon');
    const viewBeacon = videoParent.getAttribute('data-view-beacon');

    if (!clickBeacon || !videoBeacon || !viewBeacon) {
        console.error('Missing beacon data in video element:', { clickBeacon, videoBeacon, viewBeacon });
        return;
    }

    video.addEventListener('pause', () => {
        const params = new URLSearchParams(clickBeacon);
        sendTracking(params);
    });

    video.addEventListener('play', () => {
        const params = new URLSearchParams(clickBeacon);
        sendTracking(params);
    });

    video.addEventListener('ended', () => {
        const params = new URLSearchParams(videoBeacon);
        sendTracking(params);
    });

    video.addEventListener('loadedmetadata', () => {
        const params = new URLSearchParams(clickBeacon);
        sendTracking(params);
    });

    trackContainerVisibility(videoParent, viewBeacon, videoId);
};

// Fonction d'initialisation du tracking Kamino pour toutes les vidéos sur la page
export default function kaminoTrackingInit() {
    // Vider le localStorage pour les clés de tracking spécifiques
    clearTrackingData();

    document.querySelectorAll('.video-area').forEach((item) => {
        const video = item.querySelector('video[id^="billboard-video-"]');
        if (video) {
            initVideoTracking(video);
        } else {
            console.error('No video found in video area');
        }
    });
}
